import { Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppConfig } from 'app/app.config';
import { ProgressionService } from 'app/services/progression.service';
import notify from 'devextreme/ui/notify';

@Component({
  selector: 'app-multi-progression',
  templateUrl: './multi-progression.component.html',
  styleUrls: ['./multi-progression.component.scss']
})
export class MultiProgressionComponent implements OnInit {

  @Input() messages: string[] = [];
  @Input() tasksTokens: string[] = [];
  @Input() refreshFrequency: number = 1000; /* en milliseconds */
  @Input() values: number[] = [0];

  intervalIds: Map<string, number> = new Map();

  constructor(private progressionService: ProgressionService) {
    
  }

  timer(token: string) {
      this.progressionService.progress(token).then(r => {
        let p = r.type;
        //console.log("timer: progress=" + p);
        if (p < 0) {
          notify("Erreur pour : " + this.messages[token], "error", 2000);
          clearInterval(this.intervalIds[token]);
          return;
        }

        this.values[token] = p;

        if (p >= 100) {
          clearInterval(this.intervalIds[token]);
          return;
        }
      });
    
  }

  format(value) {
    return Math.round(value*100) + '%';
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.messages || changes.tasksTokens) {
      for(let token of this.tasksTokens){
        if (this.intervalIds[token]) clearInterval(this.intervalIds[token])
        this.intervalIds[token] = setInterval(() => this.timer(token), this.refreshFrequency)
      }
    } 
  }

  ngOnDestroy(): void {
    if (AppConfig.settings.trace) console.log(`==== CLEARING?: ${JSON.stringify(this.intervalIds.entries())}`)
    for(const [key, value] of this.intervalIds.entries()){
      if (AppConfig.settings.trace) console.log(`==== CLEARING 1? val: ${value}`)
      clearInterval(value)
    }
  }
  
}
