import { PercentPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { DxDataGridComponent, DxTabPanelComponent } from 'devextreme-angular';
declare var require: any
@Component({
  selector: 'app-xtractor',
  templateUrl: './xtractor.component.html',
  styleUrls: ['./xtractor.component.scss']
})
export class XtractorComponent implements OnInit {

  @Input() results: any
  @Output() syntagmes = new EventEmitter();
  @Output() sumup = new EventEmitter();

  @ViewChild('snGrid', { static: true }) auditGrid: DxDataGridComponent;
  @ViewChild('tabPanel', { static: true }) tabPanel: DxTabPanelComponent;

  pipe: any = new PercentPipe("en-US");

  plainTextValue: string = "";
  snDataSource: any = [];
  currentFilter: any;
  resume: string = "";
  phrases: any = [];
  nbrNoms: number = 0;
  nbrSyntagmes: number = 0;
  Rainbow: any = undefined;
  freqGradient: any = undefined;
  pertinenceGradient: any = undefined;
  scoreGradient: any = undefined;
  minSentScore: number = 0;
  maxSentScore: number = 100;
  startSentScore: number = 0;
  endSentScore: number = 100;
  tooltipRS: any;
  verbsByAction: any;
 

  constructor() {

    this.Rainbow = require('rainbowvis.js');

    this.freqGradient = new this.Rainbow();
    this.freqGradient.setSpectrum('white', 'gold');
    this.pertinenceGradient = new this.Rainbow();
    this.pertinenceGradient.setSpectrum('white', 'green');
    this.scoreGradient = new this.Rainbow();
    this.scoreGradient.setSpectrum('white', 'orangered');

    this.tooltipRS = {
      enabled: true,
      format: (value) => {
        return value;
      },
      showMode: "always",
      position: "bottom"
    };
  }


  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges) {
    //console.log("ResponsableSelecteurComponent:ONCHANGE:", changes);
    //const client: SimpleChange = changes.selectedClient;


    if (changes.results) {

      this.snDataSource = this.results.syntagmes;
      this.phrases = this.results.phrases;
      this.nbrSyntagmes = this.results.syntagmCount;
      this.nbrNoms = this.results.nounCount;
      this.verbsByAction = this.results.verbValues;

      /* Calculer les min et les max de chaque colonne numérique
         afin de pouvoir la coloriser par gradient */
      var mFreq = 10000;
      var MFreq = 0;
      var mPert = 1000000;
      var MPert = 0;
      var mScore = 10000;
      var MScore = 0;

      this.snDataSource.map(sn => {
        mFreq = Math.min(mFreq, sn.freq);
        MFreq = Math.max(MFreq, sn.freq);

        mPert = Math.min(mPert, sn.pertinence);
        MPert = Math.max(MPert, sn.pertinence);

        mScore = Math.min(mScore, sn.score);
        MScore = Math.max(MScore, sn.score);
        sn.proposition = sn.lexforms[0];
      });

      this.freqGradient.setNumberRange(mFreq-0.0001, MFreq);
      this.pertinenceGradient.setNumberRange(mPert - 0.1, MPert + 0.1);
      this.scoreGradient.setNumberRange(mScore - 0.1, MScore + 0.1);

   
     this.tabPanel.selectedIndex = 0;

      /* Calculer les min et les max de chaque phrase
              afin de pouvoir la coloriser par gradient */
      var minp = 1000000;
      var maxp = 0;
      this.phrases.map(p => {
        minp = Math.min(minp, p.pertinence);
        maxp = Math.max(maxp, p.pertinence);
      });
      this.minSentScore = minp - 0.1;
      this.maxSentScore = maxp + 0.1;
      this.startSentScore = this.minSentScore;
      this.endSentScore = this.maxSentScore;

      var myRainbow = new this.Rainbow();
      myRainbow.setSpectrum('white', 'orange');
      myRainbow.setNumberRange(minp - 0.1, maxp + 0.1);

      this.phrases.map(p => {
        p.bgcolor = '#' + myRainbow.colourAt(p.pertinence);
      });

      this.phrases.map(p => {
        var sent = "<span style=\"background-color:" + p.bgcolor + ";font-size:14pt\">" + p.text.replace("\n", "<br>") + "</span><br>";

        this.resume = this.resume + sent;
      });

    }



  }



  analyser_clicked() {
    this.resume = "";
    var values: any = {
      plaintext: this.plainTextValue
    }


  }

  snGrid_onCellPrepared(e) {
    // if (e.data.dateLimiteAutoswitch) 
    if (e.rowType === "data" && e.column.dataField == "freq") {
      let color = "#" + this.freqGradient.colourAt(e.data.freq);
      e.cellElement.css("background-color", color );
    } else
      if (e.rowType === "data" && e.column.dataField == "pertinence") {
        let color = "#" + this.pertinenceGradient.colourAt(e.data.pertinence);
        e.cellElement.css("background-color", color );
      } else
        if (e.rowType === "data" && e.column.dataField == "score") {
          let color = "#" + this.scoreGradient.colourAt(e.data.score);
          e.cellElement.css("background-color", color );
        }


  }
/*
onEditorPrepared(e: any) {  
  if (e.parentType === "dataRow" && e.dataField === "FirstName" && e.row.data.ID < 5) {  
    e.editorElement.querySelector("input").style.fontWeight = "bold";  
  }  
}  
onCellPrepared(e: any) {  
  if (e.rowType === "data" && e.column.dataField === "FirstName" && e.row.data.ID < 5) {  
    e.cellElement.style.fontWeight = "bold";  
  }  
}  
*/

  sliderValueChanged(e) {
    this.resume = "";
    this.phrases.map(p => {
      var sent = "<span style=\"background-color:" + p.bgcolor + ";font-size:14pt\">" + p.text.replace("\n", "<br>") + "</span><br>";
      if (p.pertinence >= e.start && p.pertinence <= e.end)
        this.resume = this.resume + sent;
    });
  }


  customizeTooltip = (arg: any) => {
    console.log(arg);
    return {

      text: arg.argument + " : " + arg.valueText + " - " + this.pipe.transform(arg.percent, "1.2-2")
    };
  }

  snGrid_itemSelectionChanged(e) {
    var tab = [];
    if(e.selectedRowsData && e.selectedRowsData.length>0) {
      e.selectedRowsData.map( e=> {
        tab.push(e.proposition);
      });
      this.syntagmes.emit(tab);
    }
  }

  onExporting(e, sheetname, filename) {
    this.onExporting(e,sheetname, filename);
  }
}
